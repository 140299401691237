/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { AuditOutlined, MinusOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Col, Row, Space } from 'antd'
import { evaluateReport, publicReport } from 'APIs'
import { Title, FormRadio, FormSelect, FormTextArea, FormUpload, Toast, PopupButton, NormalButton, PrimaryButton, LinkButton, SubmitButton, TooltipButton } from 'Components'
import { Table, TBody, Tr, Th, Td, Block } from 'Themes/facit'
import { useReportResult, useEnterKeyPress, useHistories } from 'Hooks'
import { yupResolver } from '@hookform/resolvers/yup'

import _, { findIndex, isEmpty } from 'lodash'
import { EVALUATION_STATUSES } from 'Modules/course_result/constant'
import React, { useCallback, useEffect, useState, useRef } from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { USER_ROLE } from 'Constants/auth'
import { ListButton } from './styled'
import EvaluateReportSchema from './schema'
import { STORAGE, getLocalStorage } from 'Utils'

const PUBLIC_STATUS = {
  PRIVATE: 'PRIVATE',
  PUBLIC: 'PUBLIC'
}

const EvaluateReportForm = ({
  report,
  created,
  isSuperAdmin
}) => {
  const { t } = useTranslation(['courseResult'])
  const { loadReportDetailAction } = useReportResult()
  const history = useHistories()
  const DEFAULT_VALUES = {
    submitStatus: null,
    point: report?.point,
    fileAttachFeedback: [{ pathFile: '' }],
    feedBack: ''
  }
  const [isPublicSuccess, setIsPublicSuccess] = useState(false)
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false)
  const [isFocusInput, setIsFocusInput] = useState(false)
  const [nextReport, setNextReport] = useState(null)
  const [isLastReport, setIsLastReport] = useState(false)
  const [isShowAddFile, setIsShowAddFile] = useState(false)
  const btnEvaluateRef = useRef(null)
  const form = useForm({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(EvaluateReportSchema(t))
  })
  const { handleSubmit, control, setValue, clearErrors, watch, formState: { errors } } = form
  const [pointWatch] = watch(['point'])
  const { fields: fileAttachFeedback, append, remove } = useFieldArray({
    control,
    name: 'fileAttachFeedback'
  })

  const results = JSON.parse(getLocalStorage(STORAGE.REPORT_EVALUATE))

  const renderPoint = () => _.range(5, 105, 5).map((point, index) => ({
    value: point,
    label: point
  }))

  const initForm = useCallback(() => {
    setValue('submitStatus', report.submitStatus)
    if (report.point) {
      setValue('point', {
        value: report.point,
        label: report.point
      })
    } else {
      setValue('point', null)
    }
    if (report.fileAttachFeedback?.length > 0) {
      setValue('fileAttachFeedback', report.fileAttachFeedback.map((v) => ({ pathFile: { ...v } })))
    } else {
      setValue('fileAttachFeedback', [{
        pathFile: ''
      }])
    }
    setValue('feedBack', report.feedBack)
    clearErrors()
  }, [report])
  useEnterKeyPress(btnEvaluateRef, [isFocusInput], isFocusInput)
  useEffect(() => {
    if (!isEmpty(report)) {
      initForm()
    }
  }, [report])

  useEffect(() => {
    clearErrors()
  }, [t])

  const handleShowAddFile = () => {
    setIsShowAddFile(!isShowAddFile)
  }

  const renderAttachFile = () => (!isShowAddFile ? (
    <>
      <Tr>
        <Th>{t('attach_file')}</Th>
        <Td>
          <NormalButton
            className="minius-button"
            type="primary"
            onClick={handleShowAddFile}
            disabled={isSuperAdmin && [USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR].includes(created)}
          >
            {!isShowAddFile ? <PlusOutlined /> : <MinusOutlined />}
          </NormalButton>
        </Td>
      </Tr>
    </>
  ) : fileAttachFeedback.map((field, index) => (
    <>
      <Tr>
        <Th>{t('attach_file', { value: index + 1 })}</Th>
        <Td>

          <div style={{ marginTop: 8 }} key={field.id}>
            <FormUpload
              t={t}
              acceptFormat="*"
              name={`fileAttachFeedback.${index}.pathFile`}
              maxCount={1}
              disabled={isSuperAdmin && [USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR].includes(created)}
            />
            <p>
              {t('common:require_file', {
                fileSize: '10MB'
              })}
            </p>
          </div>
          <Space style={{ marginTop: 8 }}>
            {fileAttachFeedback.length > 0 && (
              <Button
                htmlType="button"
                type="primary"
                onClick={() => (fileAttachFeedback.length > 1 ? remove(index) : handleShowAddFile())}
              >
                <MinusOutlined />
              </Button>
            )}
            {fileAttachFeedback.length < 5 && (
              <NormalButton
                className="minius-button"
                type="primary"
                onClick={() => append({ pathFile: '' })}
                disabled={isSuperAdmin && [USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR].includes(created)}
              >
                <PlusOutlined />
              </NormalButton>
            )}
          </Space>
        </Td>
      </Tr>
    </>
  )))

  const onSubmit = useCallback(
    async (formData) => {
      const params = {
        listAttachFile: formData.fileAttachFeedback
          .filter((file) => file.pathFile)
          .map(({ pathFile }) => {
            const { fileName, socialPath, type } = pathFile
            return ({
              fileName,
              socialPath,
              type
            })
          }),
        userId: report.userId,
        courseId: report.courseId,
        reportId: report.reportId,
        reportStatus: formData.submitStatus,
        point: formData.point ? formData.point.value : null,
        feedback: formData.feedBack
      }
      const data = await evaluateReport({ params })
      if (data.code === 200) {
        loadReportDetailAction({ courseId: report.courseId, userId: report.userId, reportId: report.reportId })
        setIsSubmitSuccess(true)
        setTimeout(() => {
          setIsSubmitSuccess(false)
        }, 3000)
      }
    },
    [report]
  )

  const handlePublicReport = async () => {
    const params = {
      userId: report.userId,
      courseId: report.courseId,
      reportId: report.reportId,
      publicStatus: PUBLIC_STATUS.PUBLIC
    }
    const data = await publicReport({ params })
    if (data.code === 200) {
      setIsPublicSuccess(true)
      setTimeout(() => {
        setIsPublicSuccess(false)
      }, 3000)
    }
  }

  const findIndexReportCurrent = () => {
    for (let i = 0; i < results.length; i += 1) {
      if (results[i].courseId === report.courseId && results[i].reportId === report.reportId && results[i].userId === report.userId) {
        setNextReport(results[i + 1])
        setIsLastReport(typeof results[i + 1] === 'undefined')
      }
    }
  }

  useEffect(() => {
    if (report) {
      findIndexReportCurrent()
    }
  }, [report])

  const goBack = () => {
    history.push('/course-result/report-histories')
  }

  const handleGoNextReport = () => {
    if (nextReport) {
      const { courseId, userId: UserId, reportId, created: nextCreate } = nextReport
      history.push(`/course-result/report-histories/evaluate-report?courseId=${courseId}&userId=${UserId}&reportId=${reportId}&created=${nextCreate}`)
    }
  }

  return (
    <>
      <Title icon={AuditOutlined} title={t('evaluation')} style={{ marginTop: '.25rem', minHeight: '2.5rem' }} />
      <Block style={{ margin: '0.25rem 0', padding: '0.25rem' }}>
        <FormProvider {...form}>
          <Row style={{ justifyContent: 'space-between', flexWrap: 'nowrap' }}>
            <Table className="evaluate-form">
              <TBody>
                <Tr>
                  <Th>{t('evaluationStatus')}</Th>
                  <Td>
                    <FormRadio name="submitStatus" t={t} options={EVALUATION_STATUSES.filter((status) => status.value && status.value !== 'NEW')} />
                  </Td>
                </Tr>
                <Tr>
                  <Th>{t('point_title')}</Th>
                  <Td style={{ display: 'flex', paddingTop: '0.35rem' }}>
                    <div style={{ width: 150 }}>
                      <FormSelect
                        name="point"
                        className="point"
                        defaultValue={report?.point}
                        options={renderPoint()}
                        isClearable={pointWatch?.value !== null}
                        onFocus={() => setIsFocusInput(true)}
                        onBlur={() => setIsFocusInput(false)}
                      />
                    </div>
                    <div style={{ marginLeft: 10, paddingTop: 10 }}>{t('point')}</div>
                  </Td>
                </Tr>
                {renderAttachFile()}
              </TBody>
            </Table>
            <Table className="evaluate-form">
              <TBody>
                <Tr>
                  <Th>{t('feedBack')}</Th>
                  <Td>
                    <FormTextArea
                      name="feedBack"
                      Trs={4}
                      total={4000}
                      onFocus={() => setIsFocusInput(true)}
                      onBlur={() => setIsFocusInput(false)}
                      style={{ minHeight: '7rem' }}
                    />
                  </Td>
                </Tr>
              </TBody>
            </Table>
          </Row>
          <ListButton>
            <NormalButton
              type="primary"
              onClick={goBack}
              className="back-button"
              title={t('common:back')}
              color="white"
            />
            <PopupButton
              icon={EditOutlined}
              titlePopup={t('submit_confirm')}
              textButton={t('evaluate')}
              onConfirm={handleSubmit(onSubmit)}
              disabled={!isEmpty(errors) || (isSuperAdmin && [USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR].includes(created))}
              refElement={btnEvaluateRef}
              className="button-submit"
            />
            <NormalButton
              type="primary"
              onClick={handleGoNextReport}
              className="back-button"
              title={t('common:next_person')}
              color="white"
              disabled={results.length === 0 || isLastReport}
            />
          </ListButton>
        </FormProvider>
      </Block>
      <Toast
        content={t('public_success')}
        type="success"
        isShow={isPublicSuccess}
        duration={3}
      />
      <Toast
        content={t('submit_success')}
        type="success"
        isShow={isSubmitSuccess}
        duration={3}
      />
    </>
  )
}

export default EvaluateReportForm
