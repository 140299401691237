export const GET_LIST_TALKBOARD = '@COMMUNITY_MANAGEMENT/GET_LIST_TALKBOARD'
export const GET_TALKBOARD_DETAIL = '@COMMUNITY_MANAGEMENT/GET_TALKBOARD_DETAIL'
export const CREATE_TALKBOARD = '@COMMUNITY_MANAGEMENT/CREATE_TALKBOARD'
export const UPDATE_TALKBOARD = '@COMMUNITY_MANAGEMENT/UPDATE_TALKBOARD'
export const DELETE_TALKBOARD = '@COMMUNITY_MANAGEMENT/DELETE_TALKBOARD'
export const GET_GROUP = '@COMMUNITY_MANAGEMENT/GET_GROUP'
export const GET_ATTRIBUTE = '@COMMUNITY_MANAGEMENT/GET_ATTRIBUTE'
export const GET_TAG = '@COMMUNITY_MANAGEMENT/GET_TAG'
export const UPLOAD_FILES_CREATE_TALKBOARD = '@COMMUNITY_MANAGEMENT/UPLOAD_FILES_CREATE_TALKBOARD'
export const UPLOAD_FILES_UPDATE_TALKBOARD = '@COMMUNITY_MANAGEMENT/UPLOAD_FILES_UPDATE_TALKBOARD'

export const GET_LIST_COMMENT = '@COMMUNITY_MANAGEMENT/GET_LIST_COMMENT'
export const HIDE_COMMENT = '@COMMUNITY_MANAGEMENT/HIDE_COMMENT'
export const RESET_COMMENT = '@COMMUNITY_MANAGEMENT/RESET_COMMENT'
export const RESET_TALKBOARD = '@COMMUNITY_MANAGEMENT/RESET_TALKBOARD'
