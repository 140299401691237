/* eslint-disable no-unused-vars */
import React from 'react'
import { USER_ROLE } from 'Constants/auth'
import { checkSizeColumn, formatDate } from 'Utils'
import { FORMAT_TIME } from 'Constants/formatTime'
import { Column } from 'Constants'
import { ActionGroup } from './styled'
import { TooltipCustom } from 'Components'

export default ({ t, pagination, history, RoutesName, sortInfo, language }) => {
  const column = [
    {
      title: t('management.subject'),
      dataIndex: 'subject',
      width: 200,
      ellipsis: true,
      key: 'subject',
      rules: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR],
      render: (subject) => <TooltipCustom text={subject} title={subject} isOneLine />
    },
    {
      title: t('management.sender_name'),
      dataIndex: 'senderName',
      key: 'senderName',
      width: 150,
      rules: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR],
      render: (senderName) => <TooltipCustom text={senderName} title={senderName} isEllipsis />
    },
    {
      title: t('company:company_name'),
      dataIndex: 'companyName',
      key: 'companyName',
      ellipsis: true,
      width: 120,
      rules: [USER_ROLE.NISSHOKEN_SUPER_ADMIN],
      render: (companyName) => <TooltipCustom text={companyName} title={companyName} isOneLine />
    },
    {
      title: t('management.email_address'),
      dataIndex: 'senderEmailAddress',
      key: 'senderEmailAddress',
      width: 250,
      rules: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR],
      render: (senderEmailAddress) => <TooltipCustom text={senderEmailAddress} title={senderEmailAddress} isEllipsis />
    },
    {
      title: t('common:loginId'),
      dataIndex: 'signinId',
      key: 'signinId',
      width: 250,
      rules: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR],
      render: (signinId) => <TooltipCustom text={signinId} title={signinId} isEllipsis />
    },
    {
      title: t('management.send_date_and_time'),
      dataIndex: '',
      key: '',
      sorter: true,
      sortOrder: sortInfo?.order,
      width: checkSizeColumn(language, 150, 180),
      render: (action) => {
        const value = formatDate(new Date(action?.sendTime), FORMAT_TIME.DATE_HOUR_MINUTES_SECOND)
        return <TooltipCustom text={value} title={value} isEllipsis />
      },
      rules: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR]
    },
    {
      title: t('management.the_number_record'),
      dataIndex: 'numberOfRecordSend',
      key: 'numberOfRecordSend',
      width: checkSizeColumn(language, 80, 200, 160),
      rules: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR],
      render: (numberOfRecordSend) => <TooltipCustom text={numberOfRecordSend} title={numberOfRecordSend} isEllipsis />
    },
    {
      title: t('management.sending_status'),
      dataIndex: '',
      key: '',
      width: checkSizeColumn(language, 80, 120),
      render: (action) => <TooltipCustom text={t(`management.${action.sendStatus}`)} title={t(`management.${action.sendStatus}`)} isEllipsis />,
      rules: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR]
    }
  ]

  const onView = (record) => history.push(`${RoutesName.EMAIL_DETAIL}/${record?.mailId}`)

  return [
    ...Column.orderAction({
      t,
      pagination,
      onView,
      rulesOrder: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR],
      rulesAction: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR],
      language
    }),
    ...column
  ]
}
