export const LOAD_GROUPS = '@USER/GROUP_AND_ATTRIBUTE/LOAD_GROUPS'
export const LOAD_GROUP = '@USER/GROUP_AND_ATTRIBUTE/LOAD_GROUP'
export const CREATE_GROUP = '@USER/GROUP_AND_ATTRIBUTE/CREATE_GROUP'
export const UPDATE_GROUP = '@USER/GROUP_AND_ATTRIBUTE/UPDATE_GROUP'
export const DELETE_GROUPS = '@USER/GROUP_AND_ATTRIBUTE/DELETE_GROUPS'
export const LOAD_ATTRIBUTES = '@USER/GROUP_AND_ATTRIBUTE/LOAD_ATTRIBUTES'
export const LOAD_ATTRIBUTE = '@USER/GROUP_AND_ATTRIBUTE/LOAD_ATTRIBUTE'
export const CREATE_ATTRIBUTE = '@USER/GROUP_AND_ATTRIBUTE/CREATE_ATTRIBUTE'
export const UPDATE_ATTRIBUTE = '@USER/GROUP_AND_ATTRIBUTE/UPDATE_ATTRIBUTE'
export const DELETE_ATTRIBUTES = '@USER/GROUP_AND_ATTRIBUTE/DELETE_ATTRIBUTES'
export const SAVE_FILTER = '@USER/GROUP_AND_ATTRIBUTE/SAVE_FILTER'
export const RESET_GROUP_ATTRIBUTE = '@USER/RESET_GROUP_ATTRIBUTE'
