import { ICON_SEND } from 'Assets'

const CONSTANT = {
  notification: [
    {
      name: 'management.management',
      Icon: ICON_SEND,
      path: '/contact-management/notify-management',
      fill: 'text_hight_light',
      stroke: 'none'
    }
  ]
}
export default CONSTANT
