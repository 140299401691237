export const GET_FOLDER_TREE = '@UPLOAD_FILE/GET_FOLDER_TREE'
export const GET_LIST_FOLDER_FILE = '@UPLOAD_FILE/GET_LIST_FOLDER_FILE'
export const DELETE_LIST_FOLDER_FILE = '@UPLOAD_FILE/DELETE_LIST_FOLDER_FILE'
export const GET_DETAIL_FOLDER_FILE = '@UPLOAD_FILE/GET_DETAIL_FOLDER_FILE'
export const ADD_NEW_FOLDER = '@UPLOAD_FILE/ADD_NEW_FOLDER'
export const UPDATE_FOLDER_FILE = '@UPLOAD_FILE/UPDATE_FOLDER_FILE'
export const SEARCH_FILE = '@UPLOAD_FILE/SEARCH_FILE'
export const UPLOAD_FILE = '@UPLOAD_FILE/UPLOAD_FILE'
export const MOVE_FILE = '@UPLOAD_FILE/MOVE_FILE'
export const RESET_LIST_FOLDER_FILE = '@UPLOAD_FILE/RESET_LIST_FOLDER_FILE'
