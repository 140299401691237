// MY_COMPANY
export const LOAD_COMPANY_INFO = '@MY_COMPANY/LOAD_COMPANY_INFO'
export const LOAD_COMPANY_PLAN = '@MY_COMPANY/LOAD_COMPANY_PLAN'
export const LOAD_COMPANY_PAYMENT_HISTORIES = '@MY_COMPANY/LOAD_COMPANY_PAYMENT_HISTORIES'
export const LOAD_PLAN = '@MY_COMPANY/LOAD_PLAN'
export const LOAD_DATA_PLAN = '@MY_COMPANY/LOAD_DATA_PLAN'
export const UPDATE_COMPANY_INFO = '@MY_COMPANY/UPDATE_COMPANY_INFO'
export const UPDATE_PUSH_NOTIFICATION = '@MY_COMPANY/UPDATE_PUSH_NOTIFICATION'

export const LOAD_COMPANY_PAYMENT_INVOICE = '@MY_COMPANY/LOAD_COMPANY_PAYMENT_INVOICE'
export const LOAD_COMPANY_PAYMENT_INVOICE_MONTH = '@MY_COMPANY/LOAD_COMPANY_PAYMENT_INVOICE_MONTH'
export const RESET_PAYMENT_INVOICE = '@MY_COMPANY/RESET_PAYMENT_INVOICE'

// COMPANY_DETAIL
export const LOAD_COMPANY_DETAIL = '@MY_COMPANY/LOAD_COMPANY_DETAIL'
export const BLOCK_COMPANY = '@MY_COMPANY/BLOCK_COMPANY'
export const UPDATE_TRIAL_TIME = '@MY_COMPANY/UPDATE_TRIAL_TIME'
export const CANCEL_PLAN = '@MY_COMPANY/CANCEL_PLAN'
export const UPDATE_AVATAR_PATH = '@MY_COMPANY/UPDATE_AVATAR_PATH'
