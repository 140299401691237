export const LOAD_SURVEY_ANSWER = '@SURVEY_ANSWER/LOAD_SURVEY_ANSWER'
export const LOAD_COURSE_LIST = '@SURVEY_ANSWER/LOAD_COURSE_LIST'
export const LOAD_GROUP_LIST = '@SURVEY_ANSWER/LOAD_GROUP_LIST'
export const LOAD_ATTRIBUTE_LIST = '@SURVEY_ANSWER/LOAD_ATTRIBUTE_LIST'
export const LOAD_UNIT_LIST_LESSON = '@SURVEY_ANSWER/LOAD_UNIT_LIST_LESSON'
export const LOAD_UNIT_LIST_REPORT = '@SURVEY_ANSWER/LOAD_UNIT_LIST_REPORT'
export const LOAD_UNIT_LIST_SURVEY = '@SURVEY_ANSWER/LOAD_UNIT_LIST_SURVEY'
export const LOAD_UNIT_LIST_TEST = '@SURVEY_ANSWER/LOAD_UNIT_LIST_TEST'
export const LOAD_UNIT_LIST_ALL = '@SURVEY_ANSWER/LOAD_UNIT_LIST_ALL'
export const SAVE_FILTER = '@SURVEY_ANSWER/SAVE_FILTER'
export const LOAD_SURVEY_ANSWER_DETAIL = '@SURVEY_ANSWER/LOAD_SURVEY_ANSWER_DETAIL'
export const RESET_SURVEY_ANSWER_DETAIL = '@SURVEY_ANSWER/RESET_SURVEY_ANSWER_DETAIL'
export const RESET_SURVEY_ANSWER = '@SURVEY_ANSWER/RESET_SURVEY_ANSWER'
