import React from 'react'
import { USER_ROLE } from 'Constants/auth'
import { Column } from 'Constants'
import { RoutesName } from '../routes'
import { TooltipCustom } from 'Components'

export default ({ t, history, pagination, isSuperAdmin, language }) => {
  const column = [
    {
      title: t('unit_setting:course'),
      dataIndex: 'courseName',
      key: 'courseName',
      width: 300,
      render: (courseName) => <TooltipCustom text={courseName} title={courseName} isEllipsis />
    },
    {
      title: t('unit_setting:unit'),
      dataIndex: 'unitTestName',
      key: 'unitTestName',
      width: 150,
      render: (unitTestName) => <TooltipCustom text={unitTestName} title={unitTestName} isEllipsis />
    },
    {
      title: t('unit_setting:question_setting.question_type'),
      dataIndex: 'questionType',
      key: 'questionType',
      width: 150,
      render: (questionType) => <TooltipCustom text={questionType} title={questionType} isEllipsis />
    },
    {
      title: t('unit_setting:question_setting.question_text'),
      dataIndex: 'questionText',
      key: 'questionText',
      width: 400,
      render: (questionText) => <TooltipCustom text={questionText} title={questionText} isEllipsis />
    },
    {
      title: t('test_question:create.remark'),
      dataIndex: 'note',
      key: 'note',
      width: 400,
      render: (note) => <TooltipCustom text={note} title={note} isEllipsis />
    }
  ]

  const onView = (record) => history.push(`${RoutesName.TEST_QUESTION_DETAIL}/${record.questionId}`)

  const onEdit = (record) => {
    history.push(
      isSuperAdmin
        ? `${RoutesName.EDIT_QUESTION}/${record.courseId}/${record.unitTestId}/question/${record.questionId}?locationFrom=${RoutesName.TEST_QUESTION_MANAGEMENT}&createBy=${record.created}`
        : `${RoutesName.EDIT_QUESTION}/${record.courseId}/${record.unitTestId}/question/${record.questionId}?locationFrom=${RoutesName.TEST_QUESTION_MANAGEMENT}`
    )
  }

  return [
    ...Column.orderAction({
      t,
      pagination,
      onView,
      onEdit,
      verifyDisabledEdit: (record) => isSuperAdmin && [USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR].includes(record.created),
      language
    }),
    ...column
  ]
}
