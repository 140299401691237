export const LOAD_COURSES = '@COURSE/LOAD_COURSES'
export const LOAD_ORDER_COURSE = '@COURSE/LOAD_ORDER_COURSE'
export const UPDATE_ORDER_COURSE = '@COURSE/UPDATE_ORDER_COURSE'
export const CREATE_COURSE = '@COURSE/CREATE_COURSE'
export const LOAD_COURSE = '@COURSE/LOAD_COURSE'
export const EDIT_COURSE = '@COURSE/EDIT_COURSE'
export const DELETE_COURSES = '@COURSE/DELETE_COURSES'
export const SAVE_FILTER = '@COURSE/SAVE_FILTER'
export const SAVE_INFO_COURSE = '@COURSE/SAVE_INFO_COURSE'
export const SAVE_INFO_COURSE_TO_ASSIGN = '@COURSE/SAVE_INFO_COURSE_TO_ASSIGN'
export const DISABLE_ISSUE_COURSE = '@COURSE/DISABLE_ISSUE_COURSE'
export const RESET_COURSES = '@COURSE/RESET_COURSES'
