/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can
 * update our application state. To add a new action,
 * add it to the switch statement in the reducer function
 * @param  {state} login state
 * @param  {action} login action
 */
import { REQUEST, createReducer, updateObject } from 'Stores'
import { TOGGLE_SIDEBAR, HOVER_SIDEBAR, API_82_105 } from './constants'

export const initialState = {
  sidebarCompact: false,
  sidebarHover: false,
  api82105: {
    isError: false,
    isRequiredLogout: false
  }
}

function toggleSidebar(state) {
  return updateObject(state, {
    sidebarCompact: !state.sidebarCompact
  })
}

function hoverSidebar(state) {
  return updateObject(state, {
    sidebarHover: !state.sidebarHover
  })
}

function updateApi82105(state, { payload }) {
  return updateObject(state, {
    api82105: {
      ...state.api82105,
      ...payload
    }
  })
}

// Slice reducer
export default createReducer(initialState, {
  [TOGGLE_SIDEBAR]: toggleSidebar,
  [HOVER_SIDEBAR]: hoverSidebar,

  [REQUEST(API_82_105)]: updateApi82105
})
