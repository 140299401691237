export const LOAD_UNIT_STATUS = '@UNIT_STATUS/LOAD_UNIT_STATUS'
export const LOAD_COURSE_LIST = '@UNIT_STATUS/LOAD_COURSE_LIST'
export const LOAD_GROUP_LIST = '@UNIT_STATUS/LOAD_GROUP_LIST'
export const LOAD_ATTRIBUTE_LIST = '@UNIT_STATUS/LOAD_ATTRIBUTE_LIST'
export const LOAD_UNIT_LIST_LESSON = '@UNIT_STATUS/LOAD_UNIT_LIST_LESSON'
export const LOAD_UNIT_LIST_REPORT = '@UNIT_STATUS/LOAD_UNIT_LIST_REPORT'
export const LOAD_UNIT_LIST_SURVEY = '@UNIT_STATUS/LOAD_UNIT_LIST_SURVEY'
export const LOAD_UNIT_LIST_TEST = '@UNIT_STATUS/LOAD_UNIT_LIST_TEST'
export const LOAD_UNIT_LIST_ALL = '@UNIT_STATUS/LOAD_UNIT_LIST_ALL'
export const SAVE_FILTER = '@UNIT_STATUS/SAVE_FILTER'
export const LOAD_UNIT_DETAIL = '@UNIT_STATUS/LOAD_UNIT_DETAIL'
export const LOAD_UNIT_DETAIL_BY_ID = '@UNIT_STATUS/LOAD_UNIT_DETAIL_BY_ID'
export const RESET_UNITS_LEARN_COURSE = '@UNIT_STATUS/RESET_UNITS_LEARN_COURSE'
