export const LOAD_USERS = '@USER/USER_MANAGEMENT/LOAD_USERS'
export const LOAD_USER = '@USER/USER_MANAGEMENT/LOAD_USER'
export const CREATE_USER = '@USER/USER_MANAGEMENT/CREATE_USER'
export const CREATE_NISSOKEN_USER = '@USER/USER_MANAGEMENT/CREATE_NISSOKEN_USER'
export const RESET_USERS = '@USER/USER_MANAGEMENT/RESET_USERS'

export const UPDATE_USER = '@USER/USER_MANAGEMENT/UPDATE_USER'
export const DELETE_USERS = '@USER/USER_MANAGEMENT/DELETE_USERS'
export const ASSIGN_REMOVE_GROUP = '@USER/USER_MANAGEMENT/ASSIGN_REMOVE_GROUP'
export const ASSIGN_REMOVE_ATTRIBUTE = '@USER/USER_MANAGEMENT/ASSIGN_REMOVE_ATTRIBUTE'
export const UPDATE_LOGIN_STATUS = '@USER/USER_MANAGEMENT/UPDATE_LOGIN_STATUS'
export const LOAD_USER_LEARN_STATUS = '@USER/USER_MANAGEMENT/LOAD_USER_LEARN_STATUS'
export const LOAD_USER_TEST_RESULT = '@USER/USER_MANAGEMENT/LOAD_USER_TEST_RESULT'
export const LOAD_USER_LEARN_HISTORY = '@USER/USER_MANAGEMENT/LOAD_USER_LEARN_HISTORY'

export const BATCH_REGISTER_USER = '@USER/USER_MANAGEMENT/BATCH_REGISTER_USER'
export const DOWNLOAD_CSV_TEMPLATE = '@USER/USER_MANAGEMENT/DOWNLOAD_CSV_TEMPLATE'
export const LOAD_IMPORT_USER_RESULT = '@USER/USER_MANAGEMENT/LOAD_IMPORT_USER_RESULT'
export const LOAD_ALL_COMPANY = '@USER/USER_MANAGEMENT/LOAD_ALL_COMPANY'

// export const CHECK_EXIST_EMAIL = '@USER/USER_MANAGEMENT/CHECK_EXIST_EMAIL'
