import styled from 'styled-components'

export const RowBetWeen = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
  .name {
    width: 90%;
  }
`
