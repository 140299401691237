/* eslint-disable max-len */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect } from 'react'

// import { E_LEARNING_LOGO } from 'Assets'
import { COMPANY_LOGO } from 'Assets'
import { Image, Text } from 'Components'
import { useAmountOfCompanyUnapproved, useHistories, useRoles } from 'Hooks'
import { Button, Popover, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { PopoverWrapper, Wrapper } from './styled'
import { useAmountOfCompanyAutoBlock } from 'Hooks/company'
import { RoutesName } from 'Modules/company/routes'

const HomeScreen = () => {
  const { t } = useTranslation('common')
  const history = useHistories()
  const { isSuperAdmin, isApprovalManagement } = useRoles()

  const { amountOfCompanyUnapproved, isNoticeToSuperAdmin, getAmountOfCompanyUnapprovedAction, closeNoticeToSuperAdminAction } = useAmountOfCompanyUnapproved()
  const { amountOfCompanyAutoBlock, amountOfCompanyAutoBlockFail, isNoticeAutoBlockToSuperAdmin, getAmountOfCompanyAutoBlockAction, closeNoticeAutoBlockToSuperAdminAction } = useAmountOfCompanyAutoBlock()

  const onClose = () => {
    closeNoticeToSuperAdminAction()
  }

  const onCloseBlock = () => {
    closeNoticeAutoBlockToSuperAdminAction()
  }

  useEffect(() => {
    if (isSuperAdmin || isApprovalManagement) {
      getAmountOfCompanyUnapprovedAction()
    }
    if (isSuperAdmin) {
      getAmountOfCompanyAutoBlockAction()
    }
  }, [isSuperAdmin])

  const handleLinkToDetailBlock = () => {
    closeNoticeToSuperAdminAction()
    closeNoticeAutoBlockToSuperAdminAction()
    history.push(RoutesName.COMPANY_AUTO_BLOCK)
  }

  return (
    <Wrapper id="intro">
      <Popover
        open={isNoticeToSuperAdmin}
        content={(
          <PopoverWrapper>
            <Text.error>{t('notice_approve.1', { number: amountOfCompanyUnapproved })}</Text.error>
            <Text.primary>{t('notice_approve.2')}</Text.primary>
            <Space style={{ width: '100%', justifyContent: 'center' }}>
              <Button onClick={onClose}>{t('create.close')}</Button>
            </Space>
          </PopoverWrapper>
				)}
        trigger="click"
        placement="topRight"
      >
        <div className="popover" />
      </Popover>
      <Popover
        open={isNoticeAutoBlockToSuperAdmin}
        content={(
          <PopoverWrapper>
            <Text.error>{t('notice_auto_block.1', { fail: amountOfCompanyAutoBlockFail })}</Text.error>
            <Text.error>{t('notice_auto_block.2', { number: amountOfCompanyAutoBlock })}</Text.error>
            <p
              className="block__detail--link"
              onClick={handleLinkToDetailBlock}
            >{t('notice_auto_block.3')}
            </p>
            <Space style={{ width: '100%', justifyContent: 'center' }}>
              <Button onClick={onCloseBlock}>{t('create.close')}</Button>
            </Space>
          </PopoverWrapper>
				)}
        trigger="click"
        placement="topRight"
      >
        <div className="popover__auto-block" />
      </Popover>
      <div className="container">
        <div className="container__content">
          <Image className="container__content--img" src={COMPANY_LOGO} />
          <h1 className="container__content--title">Welcome to</h1>
          <h2 className="container__content--company">Growthcollege</h2>
        </div>
      </div>
    </Wrapper>
  )
}

export default HomeScreen
