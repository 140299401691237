export const LOAD_UNIT_STATUS = '@UNIT_LEARN_COURSE/LOAD_UNIT_STATUS'
export const LOAD_COURSE_LIST = '@UNIT_LEARN_COURSE/LOAD_COURSE_LIST'
export const LOAD_GROUP_LIST = '@UNIT_LEARN_COURSE/LOAD_GROUP_LIST'
export const LOAD_ATTRIBUTE_LIST = '@UNIT_LEARN_COURSE/LOAD_ATTRIBUTE_LIST'
export const LOAD_UNIT_LIST_LESSON = '@UNIT_LEARN_COURSE/LOAD_UNIT_LIST_LESSON'
export const LOAD_UNIT_LIST_REPORT = '@UNIT_LEARN_COURSE/LOAD_UNIT_LIST_REPORT'
export const LOAD_UNIT_LIST_SURVEY = '@UNIT_LEARN_COURSE/LOAD_UNIT_LIST_SURVEY'
export const LOAD_UNIT_LIST_TEST = '@UNIT_LEARN_COURSE/LOAD_UNIT_LIST_TEST'
export const LOAD_UNIT_LIST_ALL = '@UNIT_LEARN_COURSE/LOAD_UNIT_LIST_ALL'
export const SAVE_FILTER = '@UNIT_LEARN_COURSE/SAVE_FILTER'
export const LOAD_UNIT_DETAIL = '@UNIT_LEARN_COURSE/LOAD_UNIT_DETAIL'
export const LOAD_UNIT_DETAIL_BY_ID = '@UNIT_LEARN_COURSE/LOAD_UNIT_DETAIL_BY_ID'
export const LOAD_DATE_TIME_HISTORY_VARIABLE = '@LOAD_DATE_TIME_HISTORY_VARIABLE/LOAD_DATE_TIME_HISTORY_VARIABLE'
export const RESET_UNITS_LEARN_COURSE = '@UNIT_LEARN_COURSE/RESET_UNITS_LEARN_COURSE'
