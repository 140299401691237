export const SET_VIDEO_DIMENSIONS = '@VIDEO_EDITOR/SET_VIDEO_DIMENSIONS'
export const SET_VIDEO_DURATION = '@VIDEO_EDITOR/SET_VIDEO_DURATION'
export const SET_VIDEO_DURATION_PAUSE = '@VIDEO_EDITOR/SET_VIDEO_DURATION_PAUSE'
export const SET_VIDEO_PLAY = '@VIDEO_EDITOR/SET_VIDEO_PLAY'
export const SET_CURRENT_TIME = '@VIDEO_EDITOR/SET_CURRENT_TIME'
export const ADD_ITEM_TO_TRACK = '@VIDEO_EDITOR/ADD_ITEM_TO_TRACK'
export const UPDATE_ITEM_POSITION = '@VIDEO_EDITOR/UPDATE_ITEM_POSITION'
export const UPDATE_ITEM = '@VIDEO_EDITOR/UPDATE_ITEM'
export const UPDATE_ITEM_TRACK_POSITION = '@VIDEO_EDITOR/UPDATE_ITEM_TRACK_POSITION'
export const SET_INITIAL_TIME = '@VIDEO_EDITOR/SET_INITIAL_TIME'
export const SET_RATIO_SCALE = '@VIDEO_EDITOR/SET_RATIO_SCALE'
export const REMOVE_LAYER = '@VIDEO_EDITOR/REMOVE_LAYER'
export const GET_VIDEO_DETAIL = '@VIDEO_EDITOR/GET_VIDEO_DETAIL'
export const UPDATE_PROJECT_NAME = '@VIDEO_EDITOR/UPDATE_PROJECT_NAME'
export const SET_TIMELINE_DIMENSIONS = '@VIDEO_EDITOR/SET_TIMELINE_DIMENSIONS'
export const SET_TIMELINES_INITIAL = '@VIDEO_EDITOR/SET_TIMELINES_INITIAL'
export const EXPORT_VIDEO = '@VIDEO_EDITOR/EXPORT_VIDEO'
export const EXPORT_VIDEO_SPLIT_PAUSE = '@VIDEO_EDITOR/EXPORT_VIDEO_SPLIT_PAUSE'
export const ADD_TIMELINE_SPLIT = '@VIDEO_EDITOR/ADD_TIMELINE_SPLIT'
export const ADD_TIMELINE_PAUSE = '@VIDEO_EDITOR/ADD_TIMELINE_PAUSE'
export const SUBMIT_SAVE_SPLIT_PAUSE = '@VIDEO_EDITOR/SUBMIT_SAVE_SPLIT_PAUSE'
