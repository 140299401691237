/* eslint-disable no-unused-vars */
import React from 'react'
import { TooltipCustom } from 'Components'
import { Column, USER_ROLE } from 'Constants'
import { checkSizeColumn, formatDate } from 'Utils'
import { Popover, Tag } from 'antd'

export default ({ t, pagination, language }) => {
  const column = [
    {
      title: t('common:loginId'),
      dataIndex: 'userDTO',
      key: 'userDTO',
      width: 200,
      rules: [USER_ROLE.COMPANY_ADMIN, USER_ROLE.NISSHOKEN_SUPER_ADMIN],
      render: (userDTO) => <TooltipCustom text={userDTO.signinId} title={userDTO.signinId} isEllipsis />
    },
    {
      title: t('creator'),
      dataIndex: 'userDTO',
      key: 'fullName',
      width: 150,
      render: (userDTO) => (
        <TooltipCustom text={userDTO.fullName} title={userDTO.fullName} isEllipsis />
      ),
      rules: [USER_ROLE.COMPANY_ADMIN, USER_ROLE.NISSHOKEN_SUPER_ADMIN]
    },
    {
      title: t('title'),
      dataIndex: 'talkBoardName',
      key: 'talkBoardName',
      width: 200,
      render: (text) => (
        <TooltipCustom
          text={text}
          title={text}
          isEllipsis
        />
      ),
      rules: [USER_ROLE.COMPANY_ADMIN, USER_ROLE.NISSHOKEN_SUPER_ADMIN]
    },
    {
      title: t('group'),
      dataIndex: 'departments',
      key: 'departments',
      width: 200,
      render: (text, record) => (record.departments ? (
        <Popover
          overlayClassName="group-popover"
          content={record.departments.map((item) => (
            <Tag key={record.id}>{item}</Tag>
          ))}
        >
          {record.departments.map((item, index) => index < 3 && (
          <Tag className="truncate" key={record.id}>{item}</Tag>
          ))}
        </Popover>
      ) : ''),
      rules: [USER_ROLE.COMPANY_ADMIN, USER_ROLE.NISSHOKEN_SUPER_ADMIN]
    },
    {
      title: t('attribute'),
      dataIndex: 'attributes',
      key: 'attributes',
      width: 200,
      render: (text, record) => (record.attributes ? (
        <Popover
          overlayClassName="attribute-popover"
          content={record.attributes.map((item) => (
            <Tag key={record.id}>{item}</Tag>
          ))}
        >
          {record.attributes.map((item, index) => index < 3 && (
          <Tag className="truncate" key={record.id}>{item}</Tag>
          ))}
        </Popover>
      ) : ''),
      rules: [USER_ROLE.COMPANY_ADMIN, USER_ROLE.NISSHOKEN_SUPER_ADMIN]
    },
    {
      title: t('comment'),
      dataIndex: 'content',
      key: 'content',
      width: 200,
      render: (content) => (
        <TooltipCustom
          text={content}
          title={content}
          isEllipsis
        />
      ),
      rules: [USER_ROLE.COMPANY_ADMIN, USER_ROLE.NISSHOKEN_SUPER_ADMIN]
    },
    {
      title: t('created_date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 140,
      render: (date) => <TooltipCustom text={formatDate(date)} title={formatDate(date)} isEllipsis />,
      rules: [USER_ROLE.COMPANY_ADMIN, USER_ROLE.NISSHOKEN_SUPER_ADMIN]
    },
    {
      title: t('comment_status'),
      dataIndex: 'hide',
      key: 'hide',
      width: checkSizeColumn(language, 70, 70, 100),
      render: (hide) => {
        const value = hide ? t('hide') : t('show')
        return <TooltipCustom text={value} title={value} isEllipsis />
      },
      rules: [USER_ROLE.COMPANY_ADMIN, USER_ROLE.NISSHOKEN_SUPER_ADMIN]
    },
    {
      title: t('like'),
      dataIndex: 'totalLike',
      key: 'totalLike',
      width: checkSizeColumn(language, 100, 80, 110),
      rules: [USER_ROLE.COMPANY_ADMIN, USER_ROLE.NISSHOKEN_SUPER_ADMIN]
    },
    {
      title: t('dislike'),
      dataIndex: 'totalDislike',
      key: 'totalDislike',
      width: checkSizeColumn(language, 130, 100, 150),
      rules: [USER_ROLE.COMPANY_ADMIN, USER_ROLE.NISSHOKEN_SUPER_ADMIN]
    }
  ]
  return [
    ...Column.order({
      pagination,
      rulesOrder: [USER_ROLE.COMPANY_ADMIN, USER_ROLE.NISSHOKEN_SUPER_ADMIN],
      language
    }),
    ...column
  ]
}
