export const CLEAR_LIST_ISSUE_PERMISSION = '@ISSUE_PERMISSION/CLEAR_LIST_ISSUE_PERMISSION'
export const CLEAR_LIST_USER_SELECTED = '@ISSUE_PERMISSION/CLEAR_LIST_USER_SELECTED'

export const LOAD_LIST_ISSUE_PERMISSION = '@ISSUE_PERMISSION/LOAD_LIST_ISSUE_PERMISSION'
export const CREATE_ISSUE_PERMISSION = '@ISSUE_PERMISSION/CREATE_ISSUE_PERMISSION'
export const LOAD_ISSUE_PERMISSION = '@ISSUE_PERMISSION/LOAD_ISSUE_PERMISSION'
export const EDIT_ISSUE_PERMISSION = '@ISSUE_PERMISSION/EDIT_ISSUE_PERMISSION'
export const DELETE_ISSUE_PERMISSION = '@ISSUE_PERMISSION/DELETE_ISSUE_PERMISSION'

export const LOAD_LIST_CATEGORY = '@ISSUE_PERMISSION/LOAD_LIST_CATEGORY'
export const LOAD_LIST_USER = '@ISSUE_PERMISSION/LOAD_LIST_USER'
export const LOAD_LIST_USER_SELECTED = '@ISSUE_PERMISSION/LOAD_LIST_USER_SELECTED'
export const LOAD_LIST_ATTRIBUTE = '@ISSUE_PERMISSION/LOAD_LIST_ATTRIBUTE'
export const LOAD_LIST_GROUP = '@ISSUE_PERMISSION/LOAD_LIST_GROUP'
export const LOAD_LIST_COURSE = '@ISSUE_PERMISSION/LOAD_LIST_COURSE'

export const LOAD_LIST_UPDATE_ISSUE_PERMISSION = '@ISSUE_PERMISSION/LOAD_LIST_UPDATE_ISSUE_PERMISSION'
export const RESET_ISSUES_PERMISSION = '@ISSUE_PERMISSION/RESET_ISSUES_PERMISSION'
