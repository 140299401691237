/* eslint-disable react/no-danger */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table, Radio, Checkbox, Input } from 'antd'
import { useHistories, useReportResult } from 'Hooks'
import { stripHTML } from 'Utils/string'
import { Block } from 'Themes/facit'
import { NormalButton, Title } from 'Components'
import { ListButton } from '../evaluate_report_form/styled'
import { STORAGE, getLocalStorage } from 'Utils'

const { TextArea } = Input

const Questions = ({
  report
}) => {
  const { t } = useTranslation(['courseResult'])

  const { questions, loadReportQuestionsAction } = useReportResult()
  const [isLastReport, setIsLastReport] = useState(false)
  const history = useHistories()
  const results = JSON.parse(getLocalStorage(STORAGE.REPORT_EVALUATE))

  const [nextReport, setNextReport] = useState(null)

  const findIndexReportCurrent = () => {
    for (let i = 0; i < results.length; i += 1) {
      if (results[i].courseId === report.courseId && results[i].reportId === report.reportId && results[i].userId === report.userId) {
        setNextReport(results[i + 1])
        setIsLastReport(typeof results[i + 1] === 'undefined')
      }
    }
  }

  useEffect(() => {
    if (report) {
      findIndexReportCurrent()
    }
  }, [report])

  const goBack = () => {
    history.push('/course-result/report-histories')
  }

  const handleGoNextReport = () => {
    if (nextReport) {
      const { courseId, userId: UserId, reportId, created: nextCreate } = nextReport
      history.push(`/course-result/report-histories/evaluate-report?courseId=${courseId}&userId=${UserId}&reportId=${reportId}&created=${nextCreate}`)
    }
  }

  const renderSelectOne = (record) => (
    <>
      <span>{record.questionName}</span>
      {record.listAnswer && record.listAnswer.map((answer) => (
        <>
          <Radio style={{ margin: 10 }} disabled />{answer.answerText}
        </>
      ))}
    </>
  )

  const renderSelectMulti = (record) => (
    <>
      <span>{record.questionName}</span>
      {record.listAnswer && record.listAnswer.map((answer) => (
        <>
          <Checkbox style={{ margin: 10 }} disabled />{answer.answerText}
        </>
      ))}
    </>
  )

  const renderDescription = (record) => (
    <>
      <span>{stripHTML(record.questionName)}</span>
      <TextArea style={{ color: '#323232', marginTop: 10 }} disabled value={record.answerTextDescription} />
    </>
  )

  const renderDescriptionPrint = (record, index) => (
    <div style={{ marginTop: 10, marginBottom: 10, paddingTop: 10 }} className="page-break">
      <div style={{ display: 'flex' }}><p style={{ fontWeight: 'bold', fontSize: 16, marginRight: 16 }}>{index + 1}.</p> {stripHTML(record.questionName)}</div>
      <div className="content-description" dangerouslySetInnerHTML={{ __html: record.answerTextDescription ? record.answerTextDescription.replaceAll('\n', '<br />') : '' }} />
    </div>
  )

  const columns = useMemo(() => [
    {
      title: 'No.',
      dataIndex: 'courseId',
      width: 60,
      align: 'right',
      key: (record) => record.surveyId,
      render: (text, record, index) => (
        <div>{index + 1}</div>
      )
    },
    {
      title: () => t('question'),
      dataIndex: 'questionName',
      render: (text, record) => {
        switch (record.questionType) {
          case 'SELECT_ONE': {
            return renderSelectOne(record)
          }

          case 'CHOOSE_MANY': {
            return renderSelectMulti(record)
          }

          default: {
            return renderDescription(record)
          }
        }
      }
    }
  ], [])

  useEffect(() => {
    if (report) {
      loadReportQuestionsAction({
        courseId: report.courseId,
        reportId: report.reportId,
        userId: report.userId
      })
    }
  }, [report])

  return (
    <>
      <div className="has-question">
        <Title title={t('submitted_content')} style={{ marginTop: '.25rem', minHeight: '2rem', boxShadow: 'none', paddingLeft: 'unset' }} />
        {(report?.submitStatus && report?.submitStatus !== 'NEW')
          ? questions.map((record, index) => {
            switch (record.questionType) {
              case 'SELECT_ONE': {
                return renderSelectOne(record)
              }
              case 'CHOOSE_MANY': {
                return renderSelectMulti(record)
              }
              default: {
                return renderDescriptionPrint(record, index)
              }
            }
          })
          : null}
      </div>
      <Title title={t('submitted_content')} style={{ marginTop: '.25rem', minHeight: '2rem' }} className="null-question" />
      <Block style={{ margin: '0.25rem 0', padding: '0.25rem' }}>

        {(report?.submitStatus && report?.submitStatus !== 'NEW') ? (
          <Table
            dataSource={questions}
            columns={columns}
            rowKey="index"
            pagination={false}
            className="null-question"
          />
        ) : (
          <ListButton className="null-question">
            <NormalButton
              type="primary"
              onClick={goBack}
              className="back-button"
              title={t('common:back')}
              color="white"
            />
            <span style={{ marginLeft: '20px', marginRight: '20px' }}>{t('not_submit_report')}</span>
            <NormalButton
              type="primary"
              onClick={handleGoNextReport}
              className="back-button"
              title={t('common:next_person')}
              color="white"
              disabled={results.length === 0 || isLastReport}
            />
          </ListButton>
        )}
      </Block>
    </>
  )
}

export default Questions
