export const SORT_BY_FIELD = {
  'createdAt': 'CREATED_DATE',
  'courseDto': 'COURSE_NAME',
  'email': 'EMAIL',
  'name': 'USER_NAME',
  'startTime': 'START_TIME',
  'endTime': 'END_TIME'
}

export const SORT_BY_TYPE = {
  'ascend': 'ASC',
  'descend': 'DESC'
}
