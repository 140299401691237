export const FORMAT_TIME = {
  YEAR_MONTH_DATE: 'YYYY/MM/DD',
  DATE_HOUR_MINUTES: 'YYYY/MM/DD HH:mm',
  DATE_HOUR_MINUTES_SECOND: 'YYYY/MM/DD HH:mm:ss',
  HOUR_MINUTES_SECOND_MILISECOND: 'HH:mm:ss.S',
  HOUR_MINUTES: 'HH:mm',
  YEAR_MONTH_DATE_LOWER: 'YYYY-MM-DD',
  HOUR_MINUTES_LOWER: 'HH:mm',
  HOUR_MINUTES_SECOND: 'HH:mm:ss',
  MINUTES_SECOND_LOWER: 'mm:ss',
  FULL_DATE: 'YYYY/MM/DD HH:mm',
  MONTH: 'MM'
}

export const FORMAT_TIME_JP = {
  YEAR_MONTH_DATE: 'YYYY年MM月DD日',
  DATE_HOUR_MINUTES: 'YYYY年MM月DD日 HH時mm分',
  DATE_HOUR_MINUTES_SECOND: 'YYYY年MM月DD日 HH時mm分ss秒',
  HOUR_MINUTES: 'HH時mm分',
  YEAR_MONTH_DATE_LOWER: 'YYYY年MM月DD日',
  HOUR_MINUTES_LOWER: 'HH時mm分',
  HOUR_MINUTES_SECOND: 'HH時mm分ss秒',
  MINUTES_SECOND_LOWER: 'mm分ss秒',
  FULL_DATE: 'YYYY年MM月DD日 HH時mm分'
}
