import React from 'react'
import { Column } from 'Constants'
import { RoutesName } from './routes'
import { TooltipCustom } from 'Components'

export default ({ t, pagination, handleRedirect, language }) => {
  const column = [
    {
      title: t('workspace:workspace_name'),
      dataIndex: 'workSpaceName',
      key: 'workSpaceName',
      width: 600,
      render: (workSpaceName) => <TooltipCustom text={workSpaceName} title={workSpaceName} isEllipsis />
    },
    {
      title: t('workspace:number_participants'),
      dataIndex: 'numberUserWorkSpace',
      key: 'numberUserWorkSpace',
      width: 120,
      render: (numberUserWorkSpace) => <TooltipCustom text={numberUserWorkSpace} title={numberUserWorkSpace} isEllipsis />
    },
    {
      title: t('workspace:created_date'),
      dataIndex: 'startDateWorkspace',
      key: 'startDateWorkspace ',
      width: 400,
      render: (startDateWorkspace) => <TooltipCustom text={startDateWorkspace} title={startDateWorkspace} isEllipsis />
    }
  ]

  const onEdit = (record) => handleRedirect(`${RoutesName.EDIT_WORKSPACE}/${record.id}`)

  return [
    ...Column.orderAction({
      t,
      pagination,
      onEdit,
      language
    }),
    ...column
  ]
}
