// COMPANY_MANAGEMENT
export const LOAD_CONTRACT_PLAN = '@COMPANY_MANAGEMENT/LOAD_CONTRACT_PLAN'
export const LOAD_COMPANY_LIST = '@COMPANY_MANAGEMENT/LOAD_COMPANY_LIST'
export const LOAD_COMPANY_ALL = '@COMPANY_MANAGEMENT/LOAD_COMPANY_ALL'
export const DELETE_COMPANY = '@COMPANY_MANAGEMENT/DELETE_COMPANY'
export const RESET_COMPANIES_MANAGEMENT = '@COMPANY_MANAGEMENT/RESET_COMPANIES_MANAGEMENT'

// COMPANY_WAITING
export const GET_COMPANY_LIST_WAITING = '@COMPANY_MANAGEMENT/GET_COMPANY_LIST_WAITING'
export const GET_AMOUNT_OF_COMPANY_UNAPPROVED = '@COMPANY_MANAGEMENT/GET_AMOUNT_OF_COMPANY_UNAPPROVED'
export const CLOSE_NOTICE_TO_SUPER_ADMIN = '@COMPANY_MANAGEMENT/CLOSE_NOTICE_TO_SUPER_ADMIN'
export const GET_COMPANY_WAITING_DETAIL = '@COMPANY_MANAGEMENT/GET_COMPANY_WAITING_DETAIL'
export const UPDATE_STATUS_FOR_COMPANY_WAITING = '@COMPANY_MANAGEMENT/UPDATE_STATUS_FOR_COMPANY_WAITING'
export const GET_TOTAL_OF_COMPANY_AUTO_BLOCK = '@COMPANY_MANAGEMENT/GET_TOTAL_OF_COMPANY_AUTO_BLOCK'
export const CLOSE_NOTICE_AUTO_BLOCK_TO_SUPER_ADMIN = '@COMPANY_MANAGEMENT/CLOSE_NOTICE_AUTO_BLOCK_TO_SUPER_ADMIN'
export const GET_COMPANY_LIST_AUTO_BLOCK = '@COMPANY_MANAGEMENT/GET_COMPANY_LIST_AUTO_BLOCK'
export const RESET_COMPANY_LIST_AUTO_BLOCK = '@COMPANY_MANAGEMENT/RESET_COMPANY_LIST_AUTO_BLOCK'

// COMPANY_REFUSED
export const GET_COMPANY_LIST_REFUSED = '@COMPANY_MANAGEMENT/GET_COMPANY_LIST_REFUSED'
export const MOVE_COMPANY_REFUSED_TO_WAITING = '@COMPANY_MANAGEMENT/MOVE_COMPANY_REFUSED_TO_WAITING'

// ADMIN PROFILE
export const GET_ADMIN_PROFILE = '@COMPANY_MANAGEMENT/GET_ADMIN_PROFILE'
export const UPDATE_ADMIN_PROFILE = '@COMPANY_MANAGEMENT/UPDATE_ADMIN_PROFILE'
