// Ignore toast notification on error 400
export const IGNORE_ERROR_MESSAGES = [
  'ERROR_USER_EXPIRED_TRIAL',
  'ERROR_USER_EXPIRED_PAYMENT',
  'ERROR_COMPANY_HAS_CANCELLATION',
  'ERROR_NUMBER_USER_OVER_PLAN_PACKAGE',
  'ERROR_NUMBER_DATA_OVER_PLAN_PACKAGE',
  'ERROR_PROJECT_ALREADY_EXISTS',
  '条件に一致する契約管理者の企業登録はありません。',
  'ERROR_USER_NOT_EXIST_IN_COMPANY_OR_WORKSPACE'
]

export const ERROR_MESSAGE = {
  ERROR_NUMBER_DATA_OVER_PLAN_PACKAGE: 'ERROR_NUMBER_DATA_OVER_PLAN_PACKAGE'
}

export const ERRORS_82_105 = ['ERROR_FROM_SERAKU_82105', 'COMPANY_CODE_SERAKU_ERROR_FORMAT']
