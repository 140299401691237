export const CREATE_NOTIFI = '@NOTIFY/CREATE_NOTIFI'
export const LOAD_NOTIFI = '@NOTIFY/LOAD_NOTIFI'
export const LOAD_ORDER_NOTIFI = '@NOTIFY/LOAD_ORDER_NOTIFI'
export const RESET_NOTIFICATIONS = '@NOTIFY/RESET_NOTIFICATIONS'

export const LOAD_FIND_USER = '@NOTIFY/LOAD_FIND_USER'
export const LOAD_ALL_FIND_USER_SUCCESS = '@NOTIFY/LOAD_ALL_FIND_USER_SUCCESS'
export const LOAD_ALL_FIND_USER_ERROR = '@NOTIFY/LOAD_ALL_FIND_USER_ERROR'

export const DELETE_NOTIFI = '@NOTIFY/DELETE_NOTIFI'
export const LOAD_LIST_USER = '@NOTIFY/LOAD_LIST_USER'

export const LOAD_SEND_HISTORY = '@NOTIFY/LOAD_SEND_HISTORY'
export const RESET_EMAIL_HISTORIES = '@NOTIFY/RESET_EMAIL_HISTORIES'
export const DELETE_HISTORY = '@NOTIFY/DELETE_HISTORY'
export const GET_EMAIL_DETAIL = '@NOTIFY/GET_EMAIL_DETAIL'

export const GET_NOTIFI = '@NOTIFY/GET_NOTIFI'
export const EDIT_NOTIFI = '@NOTIFY/EDIT_NOTIFI'
