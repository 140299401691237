/* eslint-disable react/prop-types */
import { HeaderSearch, FormInput, FormDatePicker } from 'Components'
import { DateLabel } from 'Components/form/FormRangePickerv2/styled'
import { FormDatePickerWrapper } from 'Themes/facit'
import { Col, Row } from 'antd'
import { pickBy } from 'lodash'
import moment from 'moment'
import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

const FilterBlock = ({ t, pagination, filter, onCancel, getCompanyListAutoBlockAction }) => {
  const defaultValues = {
    companyName: '',
    managerName: '',
    paymentFailDate: null
  }

  const form = useForm({ defaultValues })
  const { handleSubmit, reset, setValue } = form
  const handleCancel = () => {
    reset({ ...defaultValues })
    onCancel()
  }

  const onSubmit = (formData) => {
    const { companyName, managerName, paymentFailDate } = formData
    const params = {
      filter: pickBy({
        companyName,
        managerName,
        paymentFailDate
      }),
      page: 1,
      limit: pagination.limit
    }
    getCompanyListAutoBlockAction({ params })
  }

  useEffect(() => {
    const keyOfFilter = Object.keys(filter)
    if (!keyOfFilter.length) return
    keyOfFilter.forEach((item) => setValue(item, filter[item]))
  }, [filter])

  return (
    <FormProvider {...form}>
      <HeaderSearch onCancel={handleCancel} onSubmit={handleSubmit(onSubmit)}>
        <Row gutter={24}>
          <Col span={12}>
            <FormInput
              name="companyName"
              label={t('company:company_name')}
              wrapperProps={{
                colon: false,
                labelCol: { span: 10 },
                wrapperCol: { span: 14 },
                labelAlign: 'right'
              }}
            />
          </Col>
          <Col span={12}>
            <Row>
              <Col span={10}>
                <DateLabel>
                  {t('company:block_date')}
                </DateLabel>
              </Col>
              <Col span={14}>
                <FormDatePickerWrapper>
                  <FormDatePicker
                    name="paymentFailDate"
                    format={'YYYY-MM'}
                    useDate
                    placeholder={null}
                    suffixIcon={null}
                    picker="month"
                    monthCellRender={(current) => moment(current).month() + 1 + t('paymentManager:manager.month')}
                  />
                </FormDatePickerWrapper>
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <FormInput
              name="managerName"
              label={t('company:manager_name')}
              wrapperProps={{
                colon: false,
                labelCol: { span: 10 },
                wrapperCol: { span: 14 },
                labelAlign: 'right'
              }}
            />
          </Col>
        </Row>
      </HeaderSearch>
    </FormProvider>
  )
}

export default FilterBlock
