/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { EditOutlined } from '@ant-design/icons'

import { Title } from 'Components'
import { Wrapper } from 'Themes/facit'
import { useReportResult, useRoles } from 'Hooks'
import ReportDetail from './components/report_detail'
import EvaluateReportForm from './components/evaluate_report_form'
import Questions from './components/questions'
import { useReactToPrint } from 'react-to-print'

function useQuery() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

const EvaluateReport = () => {
  const { t } = useTranslation(['courseResult'])
  const { report, loadReportDetailAction } = useReportResult()
  const { isSuperAdmin } = useRoles()
  const topRef = useRef()
  const contentRef = useRef()

  const query = useQuery()
  const created = query.get('created')

  useEffect(() => {
    topRef.current?.scrollIntoView()
    loadReportDetailAction({
      courseId: query.get('courseId') || 0,
      userId: query.get('userId') || 0,
      reportId: query.get('reportId') || 0
    })
  }, [query])

  const handleGeneratePDF = useReactToPrint({
    documentTitle: `${document.getElementsByClassName('courseName')[0]?.textContent}_${document.getElementsByClassName('reportName')[0]?.textContent}_${document.getElementsByClassName('userName')[0]?.textContent}`,
    content: () => contentRef.current
  })

  return (
    <Wrapper>
      <div ref={topRef}>
        <div ref={contentRef} className="container-print">
          <Title
            icon={EditOutlined}
            title={t('evaluate_report')}
            style={{ minHeight: '2rem' }}
            downloadPDF={false}
            onDownload={handleGeneratePDF}
          />
          <ReportDetail
            report={report}
          />
          <Questions
            report={report}
          />
        </div>
        {(report?.submitStatus && report?.submitStatus !== 'NEW') && (
        <EvaluateReportForm
          report={report}
          isSuperAdmin={isSuperAdmin}
          created={created}
        />
        )}
      </div>
    </Wrapper>
  )
}

export default EvaluateReport
