/* eslint-disable max-len */
import { USER_ROLE } from 'Constants/auth'
import { FORMAT_TIME } from 'Constants/formatTime'
import { TooltipCustom } from 'Components'
import moment from 'moment'
import React from 'react'
import { Column } from 'Constants'
import { Popover, Tag } from 'antd'
import { checkSizeColumn } from 'Utils'

export default ({ t, pagination, sortInfo, language }) => {
  const column = [
    {
      title: t('management.date_time_issue'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      sortOrder: sortInfo?.columnKey === 'createdAt' && sortInfo?.order,
      width: checkSizeColumn(language, 150, 180, 160),
      render: (text) => (text ? <TooltipCustom text={moment(text).format(FORMAT_TIME.DATE_HOUR_MINUTES)} title={moment(text).format(FORMAT_TIME.DATE_HOUR_MINUTES)} /> : ''),
      rules: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR, USER_ROLE.COURSE_ADMIN]
    },
    {
      title: t('common.course'),
      dataIndex: 'courseDto',
      key: 'courseDto',
      sorter: true,
      sortOrder: sortInfo?.columnKey === 'courseDto' && sortInfo?.order,
      width: 200,
      render: (text) => (<TooltipCustom text={text.name} title={text.name} isEllipsis />),
      rules: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR, USER_ROLE.COURSE_ADMIN]
    },
    {
      title: t('common.email'),
      dataIndex: 'userDto',
      key: 'email',
      sorter: true,
      sortOrder: sortInfo?.columnKey === 'email' && sortInfo?.order,
      width: 200,
      render: (text) => (<TooltipCustom text={text.email} title={text.email} isEllipsis />),
      rules: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR, USER_ROLE.COURSE_ADMIN]
    },
    {
      title: t('common:loginId'),
      dataIndex: 'userDto',
      key: 'signinId',
      sorter: true,
      sortOrder: sortInfo?.columnKey === 'signinId' && sortInfo?.order,
      width: 200,
      render: (text) => (<TooltipCustom text={text.signinId} title={text.signinId} isEllipsis />),
      rules: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR, USER_ROLE.COURSE_ADMIN]
    },
    {
      title: t('common.name'),
      dataIndex: 'userDto',
      key: 'name',
      sorter: true,
      sortOrder: sortInfo?.columnKey === 'name' && sortInfo?.order,
      width: 150,
      render: (text) => (<TooltipCustom text={text.fullName} title={text.fullName} isEllipsis />),
      rules: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR, USER_ROLE.COURSE_ADMIN]
    },
    {
      title: t('common.group'),
      dataIndex: 'userDto',
      key: 'group',
      width: 200,
      render: (record) => (
        <Popover
          overlayClassName="group-popover"
          content={record.listGroupNames.map((item, index) => (
            <Tag key={index}>{item}</Tag>
          ))}
        >
          {record.listGroupNames.map((item, index) => index < 3 && (
            <Tag className="truncate" key={index}>{item}</Tag>
          ))}
        </Popover>
      ),
      rules: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR, USER_ROLE.COURSE_ADMIN]
    },
    {
      title: t('select_user.attribute'),
      dataIndex: 'userDto',
      key: 'attribute',
      width: 200,
      render: (record) => (
        <Popover
          overlayClassName="group-popover"
          content={record.listAttributeName.map((item, index) => (
            <Tag key={index}>{item}</Tag>
          ))}
        >
          {record.listAttributeName.map((item, index) => index < 3 && (
            <Tag className="truncate" key={index}>{item}</Tag>
          ))}
        </Popover>
      ),
      rules: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR, USER_ROLE.COURSE_ADMIN]
    },
    {
      title: t('common.course_start_date'),
      dataIndex: 'startTime',
      key: 'startTime',
      sorter: true,
      sortOrder: sortInfo?.columnKey === 'startTime' && sortInfo?.order,
      width: checkSizeColumn(language, 150, 200, 160),
      render: (text) => (<TooltipCustom text={moment(text).format(FORMAT_TIME.DATE_HOUR_MINUTES)} title={moment(text).format(FORMAT_TIME.DATE_HOUR_MINUTES)} />),
      rules: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR, USER_ROLE.COURSE_ADMIN]
    },
    {
      title: t('common.course_end_date'),
      dataIndex: 'endTime',
      key: 'endTime',
      sorter: true,
      sortOrder: sortInfo?.columnKey === 'endTime' && sortInfo?.order,
      width: checkSizeColumn(language, 150, 200, 160),
      render: (text) => (text ? (<TooltipCustom text={moment(text).format(FORMAT_TIME.DATE_HOUR_MINUTES)} title={moment(text).format(FORMAT_TIME.DATE_HOUR_MINUTES)} />) : ''),
      rules: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR, USER_ROLE.COURSE_ADMIN]
    },
    {
      title: t('common.optional_required'),
      dataIndex: 'required',
      key: 'required',
      width: checkSizeColumn(language, 130, 190, 160),
      render: (text) => (text ? <TooltipCustom text={t('options.required')} title={t('options.required')} placement="left" /> : <TooltipCustom text={t('options.optional')} title={t('options.optional')} placement="left" />),
      rules: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR, USER_ROLE.COURSE_ADMIN]
    }
  ]
  return [
    ...Column.order({
      pagination,
      rulesOrder: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR, USER_ROLE.COURSE_ADMIN],
      language
    }),
    ...column
  ]
}
