export const LOAD_AUTOMATIC_ASSIGNMENT = '@AUTO_ASSIGNMENT_COURSE/LOAD_AUTOMATIC_ASSIGNMENT'
export const DELETE_COURSE_ASSIGNMENT = '@AUTO_ASSIGNMENT_COURSE/DELETE_COURSE_ASSIGNMENT'
export const LOAD_COURSE_CATEGORY = '@AUTO_ASSIGNMENT_COURSE/LOAD_COURSE_CATEGORY'
export const LOAD_COURSE_ASSIGNMENT = '@AUTO_ASSIGNMENT_COURSE/LOAD_COURSE_ASSIGNMENT'
export const LOAD_TARGET_GROUP = '@AUTO_ASSIGNMENT_COURSE/LOAD_TARGET_GROUP'
export const LOAD_TARGET_ATTRIBUTE = '@AUTO_ASSIGNMENT_COURSE/LOAD_TARGET_ATTRIBUTE'
export const CREATE_ASSIGNMENT = '@AUTO_ASSIGNMENT_COURSE/CREATE_ASSIGNMENT'
export const UPDATE_ASSIGNMENT = '@AUTO_ASSIGNMENT_COURSE/UPDATE_ASSIGNMENT'
export const LOAD_DETAIL_ASSIGNMENT = '@AUTO_ASSIGNMENT_COURSE/LOAD_DETAIL_ASSIGNMENT'
export const RESET_AUTO_ASSIGNMENTS = '@AUTO_ASSIGNMENT_COURSE/RESET_AUTO_ASSIGNMENTS'
