import { Column, FORMAT_TIME } from 'Constants'
import { TooltipCustom } from 'Components'
import React from 'react'
import moment from 'moment'

export default ({ t, pagination, language }) => {
  const column = [
    {
      title: t('company:company_name'),
      dataIndex: 'companyName',
      key: 'companyName',
      width: 300,
      render: (companyName) => (<TooltipCustom isEllipsis text={companyName} title={companyName} />)
    },
    {
      title: t('myCompany:manager_name'),
      dataIndex: 'managerFirstName',
      key: 'managerFirstName',
      width: 250,
      render: (managerFirstName) => (<TooltipCustom isEllipsis text={managerFirstName} title={managerFirstName} />)
    },
    {
      title: t('company:block_date'),
      dataIndex: 'paymentFailDate',
      key: 'paymentFailDate',
      render: (paymentFailDate) => <TooltipCustom isEllipsis text={paymentFailDate} title={paymentFailDate} />,
      width: 120
    },
    {
      title: t('company:billed_amount'),
      dataIndex: 'totalPayment',
      key: 'totalPayment',
      width: 200,
      render: (totalPayment) => (<TooltipCustom isEllipsis text={totalPayment + t('company:price')} title={totalPayment + t('company:price')} />)
    },
    {
      title: t('company:description'),
      dataIndex: 'description',
      key: 'description',
      width: 300,
      render: (description) => (<TooltipCustom isEllipsis text={description} title={description} />)
    },
    {
      title: t('company:status'),
      dataIndex: 'isBlocked',
      key: 'isBlocked',
      width: 120,
      render: (isBlocked) => (<TooltipCustom isEllipsis text={isBlocked ? t('company:block') : ''} title={isBlocked ? t('company:block') : ''} />)
    },
    {
      title: t('company:stop_date'),
      key: 'blockDate',
      width: 120,
      render: (record) => (record?.isBlocked && record?.blockDate ? moment(record?.blockDate).format(FORMAT_TIME.YEAR_MONTH_DATE) : null)
    }
  ]

  return [
    ...Column.order({
      t,
      pagination,
      language
    }),
    ...column
  ]
}
