export const LOAD_TEST_RESULT = '@TEST_RESULT/LOAD_TEST_RESULT'
export const LOAD_COURSE_LIST = '@TEST_RESULT/LOAD_COURSE_LIST'
export const LOAD_GROUP_LIST = '@TEST_RESULT/LOAD_GROUP_LIST'
export const LOAD_ATTRIBUTE_LIST = '@TEST_RESULT/LOAD_ATTRIBUTE_LIST'
export const LOAD_UNIT_LIST_LESSON = '@TEST_RESULT/LOAD_UNIT_LIST_LESSON'
export const LOAD_UNIT_LIST_REPORT = '@TEST_RESULT/LOAD_UNIT_LIST_REPORT'
export const LOAD_UNIT_LIST_SURVEY = '@TEST_RESULT/LOAD_UNIT_LIST_SURVEY'
export const LOAD_UNIT_LIST_TEST = '@TEST_RESULT/LOAD_UNIT_LIST_TEST'
export const LOAD_UNIT_LIST_ALL = '@TEST_RESULT/LOAD_UNIT_LIST_ALL'
export const SAVE_FILTER = '@TEST_RESULT/SAVE_FILTER'
export const RESET_TEST_RESULT = '@TEST_RESULT/RESET_TEST_RESULT'
