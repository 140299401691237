/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { EditOutlined } from '@ant-design/icons'

import { STORAGE, setLocalStorage, sortFullParams } from 'Utils'
import { Title } from 'Components'
import { useAuth, useReportResult, useRoles, useLoadCompanyAll, useHistories } from 'Hooks'
import { Wrapper } from 'Themes/facit'
import tableColumns from './column'
import { FilterBlock } from './components'
import { TableSort } from '../component'
import { downloadReportHistoryCSV } from 'APIs/course-result.api'
import { isEmpty } from 'lodash'

const ReportHistories = () => {
  const { t, i18n: { language } } = useTranslation(['courseResult'])
  const {
    results,
    pagination,
    filter,
    isLoading,
    loadReportResultAction
  } = useReportResult()
  const history = useHistories()
  const { metaData } = useAuth()
  const { userId, roles } = metaData
  const { isSuperAdmin } = useRoles()
  const { companyAll } = useLoadCompanyAll()
  const { idOfNissokenCompany } = companyAll
  const { total, limit: pageSize, page: currentPage } = pagination

  const [sortInfor, setSortInfo] = useState(null)
  const [sortParams, setSortParams] = useState({})
  const [csvLoading, setCsvLoading] = useState(false)
  const [rowSelected, setRowSelected] = useState({
    selectedRowKeys: [],
    selectedRows: []
  })

  const handleTableChange = (tablePaging, _, tableSorter) => {
    const { field, order } = tableSorter
    let params = {
      ...filter,
      userId,
      page: tablePaging?.current,
      limit: tablePaging?.pageSize,
      sortBy: null,
      sortType: null
    }
    let fullParams = {
      userId,
      ...params
    }
    setRowSelected({
      selectedRowKeys: [],
      selectedRows: []
    })
    setSortInfo(tableSorter)
    setSortParams(sortFullParams(field, order, params, fullParams))
    loadReportResultAction(sortFullParams(field, order, params, fullParams))
  }

  const evaluation = (report) => {
    const { courseId, userId: UserId, reportId, created } = report
    history.push(`/course-result/report-histories/evaluate-report?courseId=${courseId}&userId=${UserId}&reportId=${reportId}&created=${created}`)
  }

  const columns = useMemo(
    () => tableColumns({ t, sortInfor, pagination, action: { evaluation }, isSuperAdmin, language }).filter((col) => col.rules.includes(roles?.[0])),
    [t, pagination, roles, isSuperAdmin, language]
  )

  const resultsData = useMemo(
    () => results.map((item, index) => ({ ...item, key: (pagination.page - 1) * pagination.limit + index + 1 })),
    [results, pagination]
  )

  const onSelectChange = (selectedRowKeys, selectedRows) => setRowSelected({
    selectedRowKeys,
    selectedRows
  })

  const onDownloadCSV = useCallback(() => {
    let data = rowSelected.selectedRows.length
      ? rowSelected.selectedRows.map((item) => ({ id: item.reportId, userId: item.userId, companyId: item.companyId }))
      : []
    setCsvLoading(true)
    const params = JSON.parse(JSON.stringify(filter))
    if (params.companyId && !isEmpty(params.companyId)) {
      params.companyId = params.companyId.value
    }
    downloadReportHistoryCSV({
      params: {
        ...params,
        page: null,
        limit: null,
        timezone: new Date().getTimezoneOffset()
      },
      data
    }).finally(() => setCsvLoading(false))
  }, [rowSelected.selectedRows, filter])

  useEffect(() => {
    if (results) {
      setLocalStorage(STORAGE.REPORT_EVALUATE, JSON.stringify(results))
    }
  }, [results])

  return (
    <Wrapper>
      <Title
        icon={EditOutlined}
        title={t('report_histories_title')}
      />
      <FilterBlock
        setSortInfo={setSortInfo}
        sortParams={sortParams}
        pageSize={pageSize}
        companyAll={companyAll}
        setRowSelected={setRowSelected}
      />
      <TableSort
        rowKey={(record) => record.key}
        dataSource={resultsData}
        columns={columns}
        loading={isLoading}
        total={total}
        currentPage={currentPage}
        pageSize={pageSize}
        selected={rowSelected.selectedRowKeys.length}
        onChange={handleTableChange}
        rowSelection={{
          selectedRowKeys: rowSelected.selectedRowKeys,
          onChange: onSelectChange,
          preserveSelectedRowKeys: true
        }}
        csv={resultsData?.length ? {
          text: t('common:download_csv'),
          onDownload: onDownloadCSV,
          loading: csvLoading || isLoading
        } : ''}
      />
    </Wrapper>
  )
}

export default ReportHistories
