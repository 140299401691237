import React from 'react'
import { TooltipCustom } from 'Components'
import { Column } from 'Constants'

export default ({ t, pagination, language }) => {
  const column = [
    {
      title: t('company:company_name'),
      dataIndex: 'companyName',
      key: 'courseName',
      render: (courseName) => <TooltipCustom text={courseName} title={courseName} isEllipsis />
    }
  ]
  return [
    ...Column.order({
      pagination,
      language
    }),
    ...column
  ]
}
