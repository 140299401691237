import React from 'react'

import { TooltipCustom } from 'Components'
import { USER_ROLE } from 'Constants/auth'
import { checkSizeColumn, formatDateShort } from 'Utils'
import { Column } from 'Constants'
import { Popover, Tag } from 'antd'

export default ({ t, sortInfo, pagination, language }) => {
  const column = [
    {
      title: t('name'),
      dataIndex: 'userName',
      key: 'userName',
      width: 150,
      sorter: true,
      sortOrder: sortInfo?.columnKey === 'userName' && sortInfo?.order,
      render: (text) => (<TooltipCustom text={text} title={text} isEllipsis />),
      rules: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR]
    },
    {
      title: t('course'),
      dataIndex: 'courseName',
      key: 'courseName',
      width: 200,
      sorter: true,
      sortOrder: sortInfo?.columnKey === 'courseName' && sortInfo?.order,
      render: (text) => (<TooltipCustom text={text} title={text} isEllipsis />),
      rules: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR]
    },
    {
      title: t('unit'),
      dataIndex: 'unitName',
      key: 'unitName',
      ellipsis: { showTitle: false },
      width: 200,
      sorter: true,
      sortOrder: sortInfo?.columnKey === 'unitName' && sortInfo?.order,
      render: (text) => (<TooltipCustom text={text} title={text} isEllipsis />),
      rules: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR]
    },
    {
      title: t('learning_start_date_and_time'),
      dataIndex: 'actualStartTime',
      key: 'actualStartTime',
      width: checkSizeColumn(language, 150, 180, 160),
      render: (text) => (<TooltipCustom text={(text !== 0 ? formatDateShort(text) : '')} title={(text !== 0 ? formatDateShort(text) : '')} />),
      rules: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR]
    },
    {
      title: t('completion_date_and_time'),
      dataIndex: 'actualCompleteTime',
      key: 'actualCompleteTime',
      width: checkSizeColumn(language, 150, 200, 180),
      sorter: true,
      sortOrder: sortInfo?.columnKey === 'actualCompleteTime' && sortInfo?.order,
      render: (text) => (<TooltipCustom text={(text !== 0 ? formatDateShort(text) : '')} title={(text !== 0 ? formatDateShort(text) : '')} />),
      rules: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR]
    },
    {
      title: t('group'),
      dataIndex: 'groupName',
      key: 'groupName',
      width: 200,
      render: (listGroup) => (
        <Popover
          overlayClassName="group-popover"
          content={listGroup.map((item, index) => (
            <Tag key={index}>{item}</Tag>
          ))}
        >
          {listGroup.map((item, index) => index < 3 && (
            <Tag className="truncate" key={index}>{item}</Tag>
          ))}
        </Popover>
      ),
      rules: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR]
    },
    {
      title: t('attribute'),
      key: 'attributes',
      dataIndex: 'attributes',
      width: 200,
      render: (attributes) => (attributes ? (
        <Popover
          overlayClassName="group-popover"
          content={attributes?.map((i) => (
            <Tag>{i}</Tag>
          ))}
        >
          {attributes.map((item, index) => index < 3 && (
            <Tag className="truncate">{item}</Tag>
          ))}
        </Popover>
      ) : ''),
      rules: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR]
    },
    {
      title: t('company:company_name'),
      dataIndex: 'companyName',
      key: 'companyName',
      width: 150,
      render: (text) => (<TooltipCustom text={text} title={text} isEllipsis />),
      rules: [USER_ROLE.NISSHOKEN_SUPER_ADMIN]
    },
    {
      title: t('email'),
      dataIndex: 'email',
      key: 'email',
      width: 250,
      sorter: true,
      sortOrder: sortInfo?.columnKey === 'email' && sortInfo?.order,
      render: (text) => (<TooltipCustom text={text} title={text} isEllipsis />),
      rules: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR]
    },
    {
      title: t('common:loginId'),
      dataIndex: 'signinId',
      key: 'signinId',
      width: 200,
      sortOrder: sortInfo?.columnKey === 'signinId' && sortInfo?.order,
      render: (text) => (<TooltipCustom text={text} title={text} isEllipsis />),
      rules: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR]
    },
    {
      title: t('common:employee_number'),
      dataIndex: 'employeeNumber',
      key: 'employeeNumber',
      width: 150,
      sortOrder: sortInfo?.columnKey === 'employeeNumber' && sortInfo?.order,
      render: (text) => (<TooltipCustom text={text} title={text} isEllipsis />),
      rules: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR]
    },
    {
      title: t('unit_completation_status'),
      dataIndex: 'complete',
      key: 'complete',
      width: checkSizeColumn(language, 150, 200),
      sorter: true,
      sortOrder: sortInfo?.columnKey === 'complete' && sortInfo?.order,
      render: (text) => (<TooltipCustom text={t(text.toLowerCase())} title={t(text.toLowerCase())} />),
      rules: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR]
    }
  ]
  return [
    ...Column.order({
      t,
      pagination,
      language,
      rulesOrder: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR],
      rulesAction: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR]
    }),
    ...column
  ]
}
