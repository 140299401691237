import React from 'react'

import { USER_ROLE } from 'Constants/auth'
import { TooltipCustom } from 'Components'
import { Column } from 'Constants'
import { checkSizeColumn } from 'Utils'

export default ({ t, pagination, action: { openModal }, language }) => {
  const column = [
    {
      title: t('course'),
      dataIndex: 'courseName',
      key: 'courseName',
      width: 500,
      render: (text) => (<TooltipCustom text={text} title={text} isEllipsis />),
      rules: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR]
    },
    {
      title: t('unit'),
      dataIndex: 'surveyName',
      key: 'surveyName',
      width: 150,
      ellipsis: true,
      render: (text) => (<TooltipCustom text={text} title={text} isEllipsis />),
      rules: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR]
    },
    {
      title: t('number_request'),
      dataIndex: 'numberOfUserRequested',
      key: 'numberOfUserRequested',
      width: checkSizeColumn(language, 80, 120),
      ellipsis: true,
      render: (text) => (<TooltipCustom text={text} title={text} />),
      rules: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR]
    },
    {
      title: t('number_response'),
      dataIndex: 'numberOfUserResponded',
      key: 'numberOfUserResponded',
      width: checkSizeColumn(language, 80, 120),
      render: (text) => (<TooltipCustom text={text} title={text} />),
      rules: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR]
    }
  ]

  const onView = (record) => openModal(record)

  return [
    ...Column.orderAction({
      t,
      pagination,
      onView,
      rulesOrder: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR],
      rulesAction: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN, USER_ROLE.SUB_ADMINISTRATOR],
      language
    }),
    ...column
  ]
}
