import React from 'react'
import moment from 'moment'
import { FORMAT_TIME } from 'Constants/formatTime'
import { bytesToSize, checkSizeColumn } from 'Utils'
import { Column } from 'Constants'
import { TooltipCustom } from 'Components'

export default ({ t, pagination, history, handleDelete, language }) => {
  const column = [
    {
      title: t('company_name'),
      dataIndex: 'companyName',
      key: 'companyName',
      width: 230,
      render: (companyName) => (
        <TooltipCustom text={companyName} title={companyName} isOneLine />
      )
    },
    {
      title: t('common:company_code'),
      dataIndex: 'companyCode',
      width: checkSizeColumn(language, 90, 120, 90),
      render: (companyCode) => <TooltipCustom text={companyCode} title={companyCode} isEllipsis />
    },
    {
      title: t('myCompany:company_code_seraku'),
      dataIndex: 'companyCodeSeraku',
      key: 'companyCodeSeraku',
      width: checkSizeColumn(language, 200, 170, 140),
      render: (text, record) => {
        const value = record.isWorkingspace !== 2 ? text : ''
        return <TooltipCustom text={value} title={value} isEllipsis />
      }
    },
    {
      title: t('common:email'),
      dataIndex: 'email',
      key: 'email',
      width: 230,
      render: (email) => <TooltipCustom text={email} title={email} isEllipsis />
    },
    {
      title: t('registration_date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => {
        const value = text ? moment(text).format(FORMAT_TIME.YEAR_MONTH_DATE) : null
        return <TooltipCustom text={value} title={value} isEllipsis />
      },
      width: checkSizeColumn(language, 100, 130, 110)
    },
    {
      title: t('last_login_date'),
      dataIndex: 'lastLogin',
      key: 'lastLogin',
      render: (text) => {
        const value = text ? moment(text).format(FORMAT_TIME.DATE_HOUR_MINUTES) : null
        return <TooltipCustom text={value} title={value} isEllipsis />
      },
      width: checkSizeColumn(language, 130, 120, 190)
    },
    {
      title: t('contract_plan'),
      dataIndex: 'planName',
      key: 'planName',
      width: checkSizeColumn(language, 100, 110, 145),
      render: (planName) => <TooltipCustom text={planName} title={planName} isEllipsis />
    },
    {
      title: t('number_of_user'),
      dataIndex: 'userUsing',
      key: 'userUsing',
      render: (_text, record) => {
        const value = t('user_using', {
          userIsUsing: record?.userIsUsing,
          totalUser: record?.maximumUser
        })
        return <TooltipCustom text={value} title={value} isEllipsis />
      },
      width: checkSizeColumn(language, 130, 140, 160)
    },
    {
      title: t('data_capacity'),
      dataIndex: 'dataUsed',
      key: 'dataUsed',
      render: (_text, record) => {
        const value = t('data_using', {
          dataUsing: bytesToSize(record?.amountOfDataUsed || 0),
          totalData: bytesToSize(record?.maximumData || 0)
        })
        return <TooltipCustom text={value} title={value} isEllipsis />
      },
      width: checkSizeColumn(language, 120, 130, 150)
    },
    {
      title: t('company_status'),
      dataIndex: 'companyStatus',
      key: 'companyStatus',
      render: (text) => <TooltipCustom text={t(text)} title={t(text)} isEllipsis />,
      width: checkSizeColumn(language, 90, 80, 120)
    }
  ]

  const onView = (record) => history.push(`/company-management/company-detail?companyId=${record.companyId}`)

  const onDelete = (record) => handleDelete(record)

  return [
    ...Column.orderAction({
      t,
      pagination,
      onView,
      onDelete,
      confirmDeleteText: 'company:warning_delete_message',
      verifyDisabledDelete: (record) => !record.isDelete,
      language
    }),
    ...column
  ]
}
