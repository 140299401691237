/* eslint-disable no-restricted-globals */
import { useDispatch, useSelector } from 'react-redux'

import {
  toggleSidebar,
  hoverSidebar,
  updateApi82105
} from 'Modules/store/actions'
import {
  makeSelectGlobal
} from 'Modules/store/selectors'

export const useRoot = () => {
  const dispatch = useDispatch()
  const { sidebarCompact, sidebarHover, api82105 } = useSelector(makeSelectGlobal())

  const toggleSidebarAction = (payload) => dispatch(toggleSidebar(payload))
  const hoverSidebarAction = (payload) => dispatch(hoverSidebar(payload))
  const updateApi82105Action = (payload) => dispatch(updateApi82105(payload))

  return {
    sidebarCompact,
    sidebarHover,
    api82105,
    toggleSidebarAction,
    hoverSidebarAction,
    updateApi82105Action
  }
}
