export const LOAD_ANALYSIS_TEST_RESULT = '@ANALYSIS_TEST/LOAD_ANALYSIS_TEST_RESULT'
export const LOAD_ANALYSIS_TEST_CHART_ANSWER = '@ANALYSIS_TEST/LOAD_ANALYSIS_TEST_CHART_ANSWER'
export const LOAD_ANALYSIS_TEST_CHART_POINT = '@ANALYSIS_TEST/LOAD_ANALYSIS_TEST_CHART_POINT'
export const LOAD_ANALYSIS_TEST_DESCRIPTION = '@ANALYSIS_TEST/LOAD_ANALYSIS_TEST_DESCRIPTION'
export const LOAD_ANALYSIS_TEST_VERSION = '@ANALYSIS_TEST/LOAD_ANALYSIS_TEST_VERSION'
export const LOAD_VERSION_TEST_LIST = '@ANALYSIS_TEST/LOAD_VERSION_TEST_LIST'
export const LOAD_ANALYSIS_SURVEY_RESULT = '@ANALYSIS_SURVEY/LOAD_ANALYSIS_SURVEY_RESULT'
export const LOAD_ANALYSIS_SURVEY_CHART_ANSWER = '@ANALYSIS_SURVEY/LOAD_ANALYSIS_SURVEY_CHART_ANSWER'
export const LOAD_ANALYSIS_SURVEY_DESCRIPTION = '@ANALYSIS_SURVEY/LOAD_ANALYSIS_SURVEY_DESCRIPTION'
export const LOAD_ANALYSIS_SURVEY_VERSION = '@ANALYSIS_SURVEY/LOAD_ANALYSIS_SURVEY_VERSION'
export const LOAD_VERSION_SURVEY_LIST = '@ANALYSIS_SURVEY/LOAD_VERSION_SURVEY_LIST'

export const LOAD_COURSE_LIST = '@ANALYSIS_TEST/LOAD_COURSE_LIST'
export const LOAD_UNIT_LIST_ALL = '@ANALYSIS_TEST/LOAD_UNIT_LIST_ALL'

export const SAVE_FILTER = '@ANALYSIS_TEST/SAVE_FILTER'
export const RESET_DATA_TABLE = '@ANALYSIS_TEST/RESET_DATA_TABLE'
export const RESET_ALL = '@ANALYSIS_TEST/RESET_ALL'
