import {
  put, takeLatest
} from 'redux-saga/effects'

import { REQUEST, SUCCESS, FAILURE } from 'Stores'
import { getReportHistories } from 'APIs'
import { getResultWithPaging } from 'Utils'
import {
  LOAD_REPORT_RESULT
} from './constants'
import { isEmpty } from 'lodash'

export function* loadReportResultsSaga({ payload }) {
  const { params } = JSON.parse(JSON.stringify(payload))

  if (params.companyId && !isEmpty(params.companyId)) {
    params.companyId = params.companyId.value
  }
  try {
    const { data } = yield getResultWithPaging({
      action: getReportHistories,
      payload: {
        params
      }
    })
    const { result: results, ...pagination } = data
    yield put({
      type: SUCCESS(LOAD_REPORT_RESULT),
      payload: {
        results,
        pagination,
        filter: payload?.params
      }
    })
  } catch (error) {
    yield put({
      type: FAILURE(LOAD_REPORT_RESULT),
      error
    })
  }
}

export default function* coureStatusSaga() {
  yield takeLatest(REQUEST(LOAD_REPORT_RESULT), loadReportResultsSaga)
}
