import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Title, Table } from 'Components'
import { MinusSquareOutlined } from '@ant-design/icons'
import { Wrapper } from 'Themes/facit'
import tableColumns from './column'
import { useCompanyAutoBlock } from 'Hooks/company'
import FilterBlock from './components/FilterBlock'
import { useHistories } from 'Hooks'

const CompanyAutoBlockScreen = () => {
  // Use hooks
  const history = useHistories()
  const { t, i18n: { language } } = useTranslation()
  const { companyListAutoBlock, getCompanyListAutoBlockAction, resetCompanyListAutoBlockAction } = useCompanyAutoBlock()
  const { pagination, filter, data, isLoading } = companyListAutoBlock

  // End use hooks

  const { page, limit, total } = pagination

  const columns = useMemo(() => tableColumns({ t, history, pagination, language }),
    [t, pagination, language])

  const handleOnChange = (tablePaging) => {
    getCompanyListAutoBlockAction({
      params: {
        page: tablePaging.current,
        limit: tablePaging.pageSize,
        filter
      }
    })
  }

  return (
    <Wrapper>
      <Title
        icon={MinusSquareOutlined}
        title={t('company:auto_block.title')}
      />
      <FilterBlock
        t={t}
        filter={filter}
        pagination={pagination}
        getCompanyListAutoBlockAction={getCompanyListAutoBlockAction}
        onCancel={resetCompanyListAutoBlockAction}
      />
      <Table
        locale={{
          emptyText: t('common:empty_data'),
          triggerDesc: t('common:sort_desc'),
          triggerAsc: t('common:sort_asc'),
          cancelSort: t('common:sort_cancel')
        }}
        rowKey={(record, index) => `${record.userId}-${index}`}
        columns={columns}
        dataSource={data}
        total={total}
        pageSize={limit}
        currentPage={page}
        onChange={handleOnChange}
        loading={isLoading}
        isHideDelete
      />
    </Wrapper>
  )
}

export default CompanyAutoBlockScreen
