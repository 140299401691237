export const USER_ROLE = {
  INDIVIDUAL_USER: 'INDIVIDUAL_USER',
  COMPANY_EMPLOYEE: 'COMPANY_EMPLOYEE',
  COMPANY_ADMIN: 'COMPANY_ADMIN',
  SUB_ADMINISTRATOR: 'SUB_ADMINISTRATOR',
  NISSHOKEN_ADMIN: 'NISSHOKEN_ADMIN',
  NISSHOKEN_SUPER_ADMIN: 'NISSHOKEN_SUPER_ADMIN',
  COURSE_ADMIN: 'COURSE_ADMIN',
  APPROVAL_MANAGEMENT: 'APPROVAL_MANAGEMENT'
}

export const USER_WORKSPACE_ROLE = {
  COMPANY_ADMIN: 0,
  WORKSPACE_ADMIN: 1,
  VIRTUAL_COMPANY: 2
}
