import React from 'react'
import { USER_ROLE } from 'Constants/auth'
import { checkSizeColumn, formatDate } from 'Utils'
import { Column } from 'Constants'
import { TooltipCustom } from 'Components'

export default ({ t, pagination, language }) => {
  const column = [
    {
      title: t('login_history_management.login_time'),
      dataIndex: 'loginDate',
      key: 'loginDate',
      render: (text) => <TooltipCustom text={formatDate(text)} title={formatDate(text)} isEllipsis />,
      width: checkSizeColumn(language, 120, 150),
      rules: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN]
    },
    {
      title: t('login_history_management.full_name'),
      dataIndex: 'fullName',
      key: 'fullName',
      width: 150,
      rules: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN],
      render: (fullName) => <TooltipCustom text={fullName} title={fullName} isEllipsis />
    },
    // {
    //   title: t('login_history_management.result'),
    //   dataIndex: 'login_result',
    //   key: 'login_result',
    //   rules: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN]
    // },
    // {
    //   title: t('login_history_management.number_of_active'),
    //   dataIndex: 'numberOfActive',
    //   key: 'numberOfActive',
    //   rules: [USER_ROLE.NISSHOKEN_ADMIN]
    // },
    {
      title: t('login_history_management.email'),
      dataIndex: 'loginId',
      key: 'loginId',
      rules: [USER_ROLE.NISSHOKEN_ADMIN],
      width: 200,
      render: (loginId) => <TooltipCustom text={loginId} title={loginId} isEllipsis />
    },
    {
      title: t('common:loginId'),
      dataIndex: 'signinId',
      key: 'signinId',
      rules: [USER_ROLE.NISSHOKEN_ADMIN],
      width: 200,
      render: (signinId) => <TooltipCustom text={signinId} title={signinId} isEllipsis />
    },
    {
      title: t('login_history_management.user_ip'),
      dataIndex: 'loginIpAddress',
      key: 'loginIpAddress',
      rules: [USER_ROLE.NISSHOKEN_ADMIN],
      width: 120,
      render: (loginIpAddress) => <TooltipCustom text={loginIpAddress} title={loginIpAddress} isEllipsis />
    },
    {
      title: t('login_history_management.user_agent'),
      dataIndex: 'userAgent',
      key: 'userAgent',
      rules: [USER_ROLE.NISSHOKEN_ADMIN],
      width: 600,
      render: (userAgent) => <TooltipCustom text={userAgent} title={userAgent} isEllipsis />
    }
  ]
  return [
    ...Column.order({
      pagination,
      rulesOrder: [USER_ROLE.NISSHOKEN_SUPER_ADMIN, USER_ROLE.NISSHOKEN_ADMIN, USER_ROLE.COMPANY_ADMIN],
      language
    }),
    ...column
  ]
}
